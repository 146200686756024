import React from "react"
import { graphql } from "gatsby"
import Layout from "~components/layouts/default"
import ContactUs from "~components/global/contact-us"
import SEO from "~components/common/seo"
import Container from "~components/common/container"
import Image from "gatsby-image"
import AniLink from "~components/common/anilink"

const ReferenceTemplate = ({ data, pageContext }) => {
  const { reference } = pageContext
  const { heroImage, images } = data

  return (
    <Layout>
      <SEO title={reference.title} description={reference.description} />
      <Container className="lg:mt-xl">
        <div className="mx-auto mb-xl">
          {heroImage && (
            <Image
              fluid={
                heroImage.childImageSharp.fluid
              }
              alt={reference.title}
              title={reference.title}
            />
          )}
        </div>
        <div className="gutter my-xl lg:grid lg:gap-md xl:grid-cols-4 xl:my-2xl">
          <header className="text-lg mb-lg lg:w-2/3 lg:ml-auto xl:mx-0 xl:w-auto xl:col-span-2">
            <h1 className="my-0">{reference.title}</h1>
          </header>
          <div className="lg:w-2/3 lg:ml-auto xl:w-auto xl:mx-0 xl:col-span-2">
            <div dangerouslySetInnerHTML={{ __html: reference.html }} />
            <AniLink className="accessory" to="/referenzen">
              Weitere Referenzen
            </AniLink>
          </div>
        </div>
      </Container>
      <Container className="flex flex-wrap lg:my-xl">
        {images.edges
          .map((item, index) => {
            return (
              <div key={index} className="w-full md:w-1/2 lg:w-1/3 p-gutter">
                <Image
                  fluid={item.node.childImageSharp.fluid}
                  alt={reference.title}
                  title={reference.title}
                />
              </div>
            )
          })}
      </Container>
      <ContactUs slug="marcel-meier" />
    </Layout>
  )
}

export default ReferenceTemplate

export const query = graphql`
  query ReferenceTemplateQuery($dir: String!) {
    heroImage: file(
      relativeDirectory: { eq: $dir }
      relativePath: { regex: "/references/.+/.+-01.(png|jpg)/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, maxHeight: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    images: allFile(
      sort: { order: ASC, fields: relativePath }
      filter: {
        relativeDirectory: { eq: $dir }
        relativePath: { regex: "/references/.+/.+(?<!-01).(png|jpg)/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 880, maxHeight: 495) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }
  }
`
